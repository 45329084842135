.youlove{
    background:url(../../../public/images/Home/form-bg.jpg) no-repeat center center;
    background-size:cover;
    position:relative;
}
.youlove::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    background: var(--balck);
    opacity: 0.6;
}

.youlove-title h2{
text-decoration:none;
color:var(--lightyellow);
text-align: center;
-webkit-text-stroke-width: 2px;
-webkit-text-stroke-color: var(--primery-bg);
font-size: 64px;
font-weight:bold;
line-height:75px
}

.youlove-main{
    background:var(--bs-white);
    padding:30px;
    border-radius: 12px;
    border: 1px solid var(--Primary-95, #E5EFFF);
    box-shadow: 0px 21px 99.7px 0px rgba(232, 195, 83, 0.34);
    width:40%;
    margin:70px auto auto auto;
    text-align:center;
}
.youlove-main h3{
    color:var(--dark);    
    font-family:var(--Inter);
    font-size: 40px;
    font-weight: 600;    
    margin:0;
}
.youlove-main form{
    margin-top:20px;
    gap:16px;
    display:flex;
    flex-direction:column;
}
.form-input{
    display:flex;
    align-items:center;
}
.form-control {
    padding:12px 20px;
    border-radius: 10px;   
    border-color:var(--primery-bg); 
    border-radius:12px;
    font-size:14px;
    font-family:var(--Inter);
    background:#F7FAFF;
}
.form-control::placeholder{color: #B3B3B3;}
.form-control.boder-right{border-right:none;border-radius:12px 0 0 12px;}
.form-control.boder-left{
border-radius:0 12px 12px 0;
}
.form-control:focus{outline:none; box-shadow:none; border-color:var(--primery-bg)}
.form-input textarea.form-control{height:110px; resize: none;}
.youlove-main .btn{
border-radius: 10px;
background: var(--SKNJ-NEW, linear-gradient(90deg, #C1A13F 3.01%, #FDEFBD 52.01%, #FDEFBD 63.01%, #B1997B 81.01%));
color:var(--dark);
font-weight: 500;
font-family:var(--Inter);
}
.painfree{padding:60px 0;}
.buttoncenter{text-align:center;}
.buttoncenter .btn {
    display: inline-block;
    margin: auto;
    padding:20px 100px;
    font-weight: bold;
    margin-top:50px;
    font-size: 18px;
    color: var(--primery);
    transition: background-color 0.5s ease, color 0.5s ease; 
}

.buttoncenter .btn:hover {
    background: var(--primery); 
    color: var(--bs-white);   
}


@media screen and (max-width:1239px) {
    .youlove-title h2 {
        font-size: 50px;
        line-height: 60px;
    }   
    .youlove-main{width:50%;} 
}
        
@media screen and (max-width:992px) {
    .youlove-main {
        width: 60%;
        margin-top:30px;
    } 
    .youlove-title h2 {
        font-size: 45px;
        line-height: 50px;
    } 
}

@media screen and (max-width:767px) {
.youlove-title h2 {
    font-size: 40px;
    line-height:50px;
}    
.youlove-main {
    padding: 20px;
    width: auto;
}
.form-control {
 padding: 10px
}
.youlove-main h3{font-size:24px;}
.form-input {gap:20px;flex-direction: column;}
.form-control.boder-left{border-radius:12px;}
.form-control.boder-right {
    border-right:1px solid var(--primery-bg);
    border-radius:12px;
}
.buttoncenter .btn{
    padding:14px 60px;
    font-size: 16px;
}
}