.durolanepage .discomfort-main{
gap:50px;
}
.durolanepage .discomfort-info{position: relative;}
.durolanepage .discomfortsection-guidance p{
    font-size:14px;
}
.durolanepage .discomfort-info::before{
    content:"";
    position:absolute;
    left:-25px;
    background: linear-gradient(45deg, var(--lightyellow), var(--primery-bg));
    height:100%;
    width:3px;

}
.durolanepage .discomfort-pic {
    width: 55%;
    background: linear-gradient(45deg, var(--lightyellow), var(--primery-bg));
    padding:3px;
    border-radius: 20px;
}

.introducingburolane::after{
    content:"";
    width:100%;
    height:3px;
    position:absolute;
    bottom:0;
    left:0;
    background: linear-gradient(45deg, var(--primery-bg), var(--primery));
}

.recognized.restoreLu-brication::after{display:none;}
.doseprf .mobility p{width:auto; margin-bottom:20px; color:var(--primery);font-weight: 700; text-align:left;}
.mobility-box {
    width:51%;
}
.doseprf .mobility h2{margin:0;}
.mobility{
    gap:40px;
}
.mobility ol {
    list-style: decimal;
    padding-left: 15px;
    display:flex;
    gap:20px;
    flex-direction:column;
}

.medicationsmedications{
    position:relative;
}
.medicationsmedications::before,
.medicationsmedications::after{
    content:"";
    width:100%;
    height:3px;
    position:absolute;
    top:0;
    left:0;
    background: linear-gradient(45deg, var(--primery-bg), var(--primery));
}
.medicationsmedications::after{
    bottom:0;
    top:auto;
}

@media screen and (max-width:992px) {
.durolanepage .discomfort-main {
    gap: 30px;
}
.durolanepage .discomfort-info::before{
    left: -16px;width: 2px;    
}
.discomfortsection-guidance p,
.durolanepage .discomfortsection-guidance p {
    font-size: 13px;
}
.mobility {
    gap: 20px;
}
.mobility-box {
    width: 49%;
}
}

@media screen and (max-width: 767px) {
.mobility-box {
    width: auto;
}
.durolanepage .discomfort-pic{margin:auto; width:auto;}
.durolanepage .discomfort-info::before {
    left: -10px; 
}
    
}