.confidence{
    gap:40px;
    justify-content: space-between;
    align-items:center;
    padding-right:calc(50% - 595px);
}
.confidence-info {
    display: flex;
    flex-direction: column;    
    align-items: flex-start;
}

.confidence-pic img {
    max-width:650px;
    border-radius:0 25px 25px 0;
}
.confidence-info strong {
    border-bottom: 3px solid var(--lightyellow);
    color: #1A3129;
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 8px;
    margin-bottom:10px;
    display: inline-block;
}
.removal-info h2{
    text-decoration:none;
    font-size:40px;
    display: block;
    margin-bottom:20px;
}

.confidence-info p{
color:var(--dark);
text-align: justify;
}
/* treatmentpersonalised css */
.treatmentpersonalised{
background:var(--golden);
padding-right:50px;
padding-top:50px;
padding-bottom:50px;
border-radius:0 25px 25px 0;
padding-left:calc(50% - 595px);
margin-right:calc((100% - 1198px) / 2);
}
/* treatmentpersonalised css end*/

/* personalisedaftercare css */
.personalisedaftercare{
    background:var(--golden);
    padding-top:50px;
    padding-bottom:50px;
    border-radius:25px 0  0 25px;
    padding-left:50px;
    margin-left:calc((100% - 1198px) / 2);
    padding-right: calc(50% - 595px);
    }
  .personalisedaftercare .confidence-info ul{
    margin-bottom:16px;
    list-style:outside;
    padding-left:16px;
  }

  .lesionlooks {
    background: linear-gradient(45deg, #162731, #C1A13F);
    padding: 3px 0;
}
  .consultationsurgical{
    padding:100px 0px;
    background:var(--bs-white);    
  }
  .consultationsurgical h4{margin:0; text-align:center;
    color:var(--lightyellow);
    font-family:var(--Poppins);
    font-size: 35px;    
    font-weight: 600;
    line-height:50px; 
}
/* personalisedaftercare css end*/

/* .consultationtop{
    margin-top:-90px;
} */
@media screen and (min-width:1920px) {  
.confidence.confidence02{
  width:1240px; 
  margin:auto;
  padding-right:0;
  }
  .confidence-pic img{border-radius:25px; }  
}

@media screen and (max-width:1239px) {
  .confidence{width:auto;}
  .confidence{align-items: self-start; gap:20px;}
  .removal-info h2{font-size:30px;}
.treatmentpersonalised{
 margin-right: calc((100% - 938px) / 2);
 padding:20px; 
}
.personalisedaftercare {
  padding:20px;
  margin-left: calc((100% - 938px) / 2);  
}
.confidence-pic img {
  max-width: 400px;
}
.confidence-info{
  padding-right:20px;
}
.consultationtop {
  margin-top: 0;
}

}

@media screen and (max-width:992px) {
.confidence-pic img {width: 100%; max-width: 550px;border-radius:10px;}
.confidence{
  flex-direction:column;
  padding-left:20px;
  padding-right:20px;
}
.treatmentpersonalised {margin:0 20px; border-radius:10px; padding:20px;} 
.personalisedaftercare{margin:50px 20px; border-radius:10px; padding:20px;}
.consultationsurgical h4{font-size:25px; line-height:35px;}

.confidence-info strong {
  border-bottom: 2px solid var(--lightyellow);
  color: #1A3129;
  font-size: 18px;
}
.removal-info h2 {
  font-size: 22px;
  margin-bottom: 10px;
  line-height: 30px;
}
.confidence{align-items:center;}
}

@media screen and (max-width: 767px) {
  .consultationsurgical{padding:60px 0;}

}