.fasater-title {
    max-width:840px;
    margin: auto;  
}
.fasater-title h2{    
    margin-bottom:10px;
}
.fasater-title p{margin-bottom:0;}

.non-surgical-main{
    gap:30px;
    margin-top:30px;
}
.non-surgical-box {
    border-radius: 10px;
    border: 1px solid #B5C0D3;
    display: flex;
    padding:20px;
    flex-direction: column;
    align-items: center;
    gap:20px;
    -webkit-transition: all 0.5s ease; 
    -moz-transition: all 0.5s ease;   
    -o-transition: all 0.5s ease;     
    transition: all 0.5s ease;   
    background:var(--bs-white);     
}

.non-surgical-main .row.justify-content-between{margin:0;}
.non-surgical-box02 {
    width:49%;
    gap:30px;
}

.non-surgical-box:hover{
    box-shadow:inset 1px -3px 20px #00000052;
}

.non-surgical-pic {
    display: flex;
    min-height:80px;
    align-items: center;
    justify-content: center;
}
.non-surgical-pic img{
    max-width:150px;
}
.non-surgical-info {
    text-align: left;    
    display: flex;
    flex-direction: column;
    min-height:170px;
    justify-content: space-between;
}
.non-surgical-info p{
    margin-bottom:0;
    color:#666;
    font-family:var(--Inter);            
}
.non-surgical-info strong{
font-family:var(--Inter);
color: var(--primery-bg);
font-size: 20px;
font-weight: 500;
line-height: 26px;
text-decoration: underline;
text-decoration-thickness: 1px; 
text-underline-offset: 3px; 
text-decoration-color:var(--black);
}

.non-surgical-info .btn {
    color: var(--lightyellow);
}

.non-surgical-info .btn svg path{
fill:var(--lightyellow);
}

@media screen and (max-width:1239px) {
.non-surgical-info strong{font-size:18px;}
.non-surgical-info{min-height: 190px;}

}
    
@media screen and (max-width:992px) {
.non-surgical-box{gap:10px;}
.non-surgical-info strong {
    font-size: 16px;
    line-height: 20px;
}
.non-surgical-info {
    min-height: 165px;
}
    
}

@media screen and (max-width:767px) {
.non-surgical-info{text-align:center; min-height: inherit;gap:15px;}
.non-surgical-main .row.justify-content-between{flex-direction:column; gap:20px;}    
.non-surgical-box{width:100%;}
.non-surgical-main .row,
.non-surgical-main{gap:20px;}    

}