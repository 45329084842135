.faq-page .hero-info h2{margin-bottom:0;}
.faq-title p{
    font-family:var(--Inter);
}
.faq-title a{
    background:linear-gradient(90deg, var(--primery) 1.7%, var(--primery-bg) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;    
}
.faq-box-main {
    margin-top: 30px;
}

.faq-box {
    border-bottom: 1px solid #E5EFFF;
    position: relative;
    transition: background-color 0.3s ease; 
}
.active .faq-question,
.faq-box.active {
    background-color: #E5EFFF; 
}

.faq-question {
    cursor: pointer;
    font-weight: 500;
    padding: 15px 20px 15px 15px;
    font-family: var(--Inter);
    font-size: 14px;
    line-height: 18px;
    min-height:66px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background:var(--bs-white);
}

.faq-answer {
    max-height: 0;
    overflow: hidden; 
    padding: 0 15px; 
    font-size: 14px;
    line-height: 22px;
    transition: max-height 0.3s ease, padding 0.3s ease; 
}

.faq-answer.show {
    max-height: 500px; 
    padding:0 15px 15px 15px;
}

.faq-answer ul {
    list-style-type: disc;
    padding-left: 20px;
}

.faq-answer ul li {
    margin-bottom: 5px;
}

.faq-box svg {
    position: absolute;
    right: 0;
    transition: transform 0.3s ease; 
}

.faq-box.active svg {
    transform: rotate(45deg);
}

@media screen and (max-width:992px) {
    .faq-question{
    padding: 10px 16px 10px 10px;    
    font-size: 12px;
    line-height: 16px;
    min-height: 45px;
    }
    .faq-answer.show {
        padding: 0 10px 10px 10px;
    }
    .faq-answer{
        font-size: 13px;
        line-height: 20px;
    }
}
