.testimonial {
    background: #FAF2D9;    
}
.testimonial-title{
    display:flex;
    flex-direction:column;
    align-items:center;
}
.testimonial-title h2{text-decoration:none; margin:8px 0 30px 0;}
.testimonial-title strong span{
    font-family:var(--Inter);        
    font-weight:600;
    line-height: normal;
    background: var(--SKNJ-NEW, linear-gradient(90deg, #C1A13F 3.01%, #FDEFBD 52.01%, #FDEFBD 63.01%, #B1997B 81.01%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;

}
.testimonial-title strong{
border-radius: 100px;
border: 1px solid #FFC000;
background:var(--primery-bg);
padding:10px 18px;
}
.testimonial-box{
display: flex;
padding: 30px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
border-radius: 16px;
border: 1px solid var(--primery-bg);
background: var(--bs-white);
margin-bottom:1px;
}
.testimonial-pic{display:flex; gap:10px; align-items:center;}
.testimonial-info h5{
    margin:0;
    font-family:var(--Inter);
    font-size: 18px;
    font-weight: 600;
    background: var(--SKNJ, linear-gradient(90deg, var(--lightyellow) 1.7%, var(--primery-bg) 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }
.testimonial-info p{
color: var(--Grey-50, #808080);
font-family:var(--Inter);
margin:16px 0;
min-height:192px;
display: flex;
align-items: center;
    }

.testimonial-pic img{
    width:36px;
    height:36px;
    border-radius:50%;
    box-shadow: 0px 0px 0px 4px #E7F1FF;
}
.testimonial-pic h6{
    margin:0;
    color:var(--dark);
    font-family:var(--Inter);
    font-size:16px;
    font-weight: 500;
    line-height: 150%;
}

@media screen and (max-width:992px) {
.testimonial-info p{min-height:160px;}

}

@media screen and (max-width:767px) {
.testimonial-box{padding:20px;}
.testimonial-info p {
    min-height:initial;
}
    
}