.restoreLu-brication{
    position: relative;
}
.restoreLu-brication::after{
    content: "";
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(45deg, var(--primery-bg), var(--primery));
}
.restoreLu-brication h2{font-weight:600; text-decoration:none;}
.restoreLu-brication-main{
   gap:30px;
   margin-top:80px;
}
.restoreLu-brication-box:hover .restoreLu-brication-no{
    color:var(--lightyellow);
    -webkit-text-stroke-color:transparent;
}
.restoreLu-brication-no{
color:var(--golden);
-webkit-text-stroke-width: 1px;
-webkit-text-stroke-color:var(--lightyellow);
font-family: "Inter", sans-serif;
font-size: 80px;
font-weight: 600;
position: absolute;
top: -30px;
left: 0px;
z-index: 1;
-webkit-transition: color 0.5s ease-in-out, -webkit-text-stroke-color 0.5s ease-in-out;
-moz-transition: color 0.5s ease-in-out, -webkit-text-stroke-color 0.5s ease-in-out;
-o-transition: color 0.5s ease-in-out, -webkit-text-stroke-color 0.5s ease-in-out;
transition: color 0.5s ease-in-out, -webkit-text-stroke-color 0.5s ease-in-out;
}
.restoreLu-brication-box {
width: 32%;
position: relative;
display: flex;
padding: 20px;
flex-direction:column;
align-items:flex-start;
gap:20px;
border-radius: 12px;
border: 1px solid var(--golden);
background:var(--bs-white);
-webkit-transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
-moz-transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
-o-transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}

.restoreLu-brication-box:hover {
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    -ms-transform: translateY(5px);
    -o-transform: translateY(5px);
    transform: translateY(5px);
    background:var(--golden);
    -webkit-box-shadow: 0px 6px 25px 1px #4040401f;
    -moz-box-shadow: 0px 6px 25px 1px #4040401f;
    box-shadow: 0px 6px 25px 1px #4040401f;
}
.restoreLu-brication-box span{
    color:var(--dark);    
    font-family:var(--Poppins);
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
}
.restoreLu-brication-box p{
    margin-bottom:0; 
    text-align:justify;
    font-family:var(--Inter);
}
.restoreLu-brication-box svg {
    margin: auto;
}

.restoreLu-brication-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.restoreLu-brication.recognized{padding:150px 0;}