.ultrasoundguide h3{
    color: var(--balck);
    font-family:var(--Poppins);
    font-size: 32px;
    font-weight: 600;
    line-height: 53px;
    margin-bottom:0;
    }

.ultrasoundguide{
    position:relative;
    }
    .ultrasoundguide::after{
    content:"";
    width:100%;
    height:3px;
    position:absolute;
    bottom:0; 
    left:0;
    background: linear-gradient(45deg, var(--primery), var(--primery-bg));
} 
.gradinentthree .ultrasoundguide h3{
    background: linear-gradient(90deg, #162731 0%, #C1A13F 83.41%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
        