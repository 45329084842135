.unparralleled-main{
padding-left:calc((100% - 1200px) / 2);
gap:30px;
justify-content: space-between;
margin-top:80px;
}
.unparralleled-pic {
    width:48.5%;
    display: flex;
    background: linear-gradient(45deg, #162731, #C1A13F);
    padding: 8px;
    border-radius: 12px;
}
.unparralleled-pic video {
    width: 100%;
    border-radius: 12px;
}
.unparralleled-info {
    width:49%;
    background: var(--golden);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    border-radius: 12px 0 0 12px;
    padding-right:calc(100% - 1202px);
}
.unparralleled-info h2{
    margin-bottom:20px;
    font-weight: 600;
}

@media screen and (min-width:1920px) {
.unparralleled-info{padding-right:calc(100% - 1222px);}
.conditions-wetreat .container-fluid {
    max-width: initial;
}
}
@media screen and (max-width:1239px) {
.unparralleled-accuracy{padding-left:20px;}
.unparralleled-info{width:48%;}
.unparralleled-info p{padding-right:15px;}
}

@media screen and (max-width:992px) {
.unparralleled-main{gap:10px;}
.unparralleled-info {
    width:46%;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.unparralleled-pic {
    width:52.5%;
    padding:4px;
}

}
@media screen and (max-width:767px) {
.unparralleled-accuracy{padding-right:20px;}
.unparralleled-pic{
  width:100%;  
}
.unparralleled-info{width:100%; border-radius:10px;padding:20px;}
.unparralleled-info p{padding:0;}
.unparralleled-main {
    gap:20px;
}
}