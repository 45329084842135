.pricing-main{
    margin-top:30px;
    background: linear-gradient(45deg, var(--golden), var(--lightyellow));      
    border-radius:40px;     
    padding:4px;       
}
.pricing-main02{
    margin-top:50px;
}
.tissueinjections{
    background:var(--bs-white);
    padding:50px;
    border-radius:40px;
}
.tissueinjections h3{
    color: var(--dar);
    text-align: center;
    font-size: 36px;
    font-weight: 600;  
    margin-bottom:0;  
}
.guidance-pic{text-align:center; margin:30px 0;}
.guidance-pic img{
    width:100%;
    padding:4px;
    border-radius: 20px;
    background: linear-gradient(45deg, var(--lightyellow),  var(--primery-bg));
    max-width:910px;
}
.guidance-boxmain {
    gap: 30px;
}
.guidance-box{
    gap:20px;
    width:50%;
}
.pricing-main02 .guidance-box {
    width: 83%;
}
.guidance-info{
width:100%;
border-radius: 6px;
border: 1px solid #E5EFFF;
padding:10px 20px;
min-height: 80px;
}

.guidance-info p{margin:0;
    color: var(--dark);
    font-family:var(--Inter);
    font-size: 18px;
    line-height: normal;
}
.guidance-info p span{
    color: #808080;        
    font-size: 14px;
    font-weight:300;    
    display:block;
    padding-top:5px;
}
.guidance-info  strong{
font-size: 20px;
font-weight: 700;
background:linear-gradient(90deg, var(--primery-bg) 0%, var(--primery) 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

@media screen and (max-width:1239px) {

}

@media screen and (max-width:992px) {
    .tissueinjections h3{font-size:23px;}
    .guidance-info p{font-size:14px;}
    .guidance-info p span{font-size:11px;}
    .guidance-info strong {
        font-size: 16px;
    }
    .guidance-info{padding:5px;min-height: 60px;}
}

@media screen and (max-width:767px) {
    .guidance-boxmain{flex-direction:column;}
    .guidance-box{width:100%;}
    .pricing-main{border-radius:20px;}
    .tissueinjections{padding:20px; border-radius:20px;}
    .pricing-main02 .guidance-box {
        width: auto;
    }
}