    .discomfortsection-guidance span {
        color: var(--primery-bg);
        font-size: 24px;
        font-weight: 300;
        border-bottom: 2px solid var(--lightyellow);
        padding-bottom: 8px;
        display: inline-block;
    }

    .discomfortsection-guidance h2 {
        display: block;
        margin: 0 0 30px 0;
    }

    .discomfortsection-guidance p {
        font-family:var(--Poppins);
        text-align: justify;
    }
    .discomfort-main{
        margin-top:80px;
        gap:60px;
    }

    .discomfort-pic{
        width: 50%;
    }
    .discomfort-pic img{
        border-radius: 20px;
    }
    .discomfort-info {
        width:70%;
    }

    .introducingplatelet{
        position: relative;
    }
    .introducingplatelet::before{
        content:"";
        width:100%;
        height:3px;
        position:absolute;
        top:0;
        left:0;
        background: linear-gradient(45deg, var(--primery-bg), var(--primery));
}
.doseprf{
    background:var(--primery-bg);    
    color:var(--bs-white);     
}
.doseprf h2{
    color:var(--bs-white);
    margin-bottom:30px;
    text-decoration-thickness:3px; 
    text-underline-offset:15px; 
    text-decoration-color:var(--lightyellow);   
}
.doseprf p{
text-align: justify;
width: 65%;
margin: auto;    
}
.step-process-main{
    gap:60px;
    margin-top:30px;   
}

.step-process-box{
    width:30%;    
    position:relative;
    background:var(--bs-white);
}
.step-process-box::after {
    content: "";
    position: absolute;
    right: -60px;
    width: 27%;
    border-top:dashed 2px var(--dark);
    height: 3px;
    top: 130px;
}
.step-process-box:last-child::after{display:none;}
.step-process-box p{
    margin:20px 0 0 0;
    display: flex;
    align-items: center;
    gap: 15px;
    font-family:var(--Inter);
}
.step-process-box p span{
color:var(--dark);
font-size: 60px;
font-weight: 600;
line-height: 150%;
}
.step-process-pic{
    overflow:hidden;
    position: relative;
    z-index: 1;
}
.step-process-pic img{
    border-radius:10px;
    transition: transform 0.5s ease-in-out;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    -moz-transition: -moz-transform 0.5s ease-in-out;
    -ms-transition: -ms-transform 0.5s ease-in-out; 
    -o-transition: -o-transform 0.5s ease-in-out;
}
.step-process-box:hover img{
    transform:scale(1.2);    
}
.bordertopprocess{
    position: relative;
}
.bordertopprocess::after,
.bordertopprocess::before{
    content:"";
    width:100%;
    height:3px;
    position:absolute;
    top:0;
    left:0;
    background: linear-gradient(45deg, var(--primery-bg), var(--primery));
}
.bordertopprocess::after{
    top:auto;
    bottom:0;
    background: linear-gradient(45deg, var(--primery), var(--primery-bg));
}
.bordertopprocess .step-process-box::after{border:none;}

@media screen and (max-width:1239px) {
    .discomfort-main{gap:30px;margin-top:40px;}
    .doseprf p{width:80%;}
    .step-process-main{gap:10px; justify-content:space-between;}
    .step-process-box p span{font-size:45px;}    
}

@media screen and (max-width:992px) {
    .step-process-box p{gap:10px;}
    .discomfortsection-guidance span{font-size:16px;}
}
@media screen and (max-width: 767px) {
    .step-process-box p{width:80%;}
.discomfort-main{flex-direction:column;}
.discomfort-pic,
.discomfort-info{width:100%;}
.discomfort-pic{text-align:center;}
.discomfort-pic img{max-width:450px;width:100%; border-radius: 10px;}
.doseprf h2{text-underline-offset: 5px;text-decoration-thickness: 2px;}
.doseprf p {
    width: auto;
}
.step-process-main{
    flex-direction:column;
    gap: 20px;
}
.step-process-box{width:100%;}
.step-process-box::after {
    transform: rotate(90deg);
    top: 80%;
    left: auto;
    width: 52%;
    height: 1px;
    border-top: dashed 1px #cfcfcf;
}
}