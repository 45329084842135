.clinic-director {
    padding-right:calc((100% - 1198px) / 2);
    gap:50px;
    justify-content: space-between;
    align-items:center;
}
.aesthetics-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start;
}
.director-pic{
    background:var(--golden);
    padding-left: calc(100% - 1198px);
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius:0 10px 10px 0;
}
.director-pic img {
    max-width: 420px;
    border-radius:10px;
}
.aesthetics-info strong {
    border-bottom: 3px solid var(--lightyellow);
    color: #1A3129;
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 8px;
    margin-bottom:10px;
}
.aesthetics-info h2{
    text-decoration:none;
    font-size:40px;
}
.aesthetics-info span{
    color:#9B9B9B;
    font-size: 16px;
    font-weight: 500;
    line-height: 120.168%;
    letter-spacing: 0.32px;  
}
.director-info p{
color:var(--dark);
text-align: justify;
}

@media screen and (max-width:1239px) {
.clinic-director {
padding-right:20px;   
gap:30px; 
}       
.director-pic img {
max-width: 350px; 
}
.director-pic {
    padding:10px 10px 10px 20px; 
}

}
        
@media screen and (max-width:992px) {
.director-pic img {
    max-width: 260px;
}
.aesthetics-info{ margin-bottom:10px;}
.aesthetics-info span{font-size:14px;}
.aesthetics-info h2{font-size:30px;}
.aesthetics-info strong{font-size: 18px;padding-bottom: 5px;margin-bottom: 5px;}
.clinic-director{gap: 20px;}

}

@media screen and (max-width:767px) {
    .clinic-director{flex-direction:column; padding-left:20px; padding-right:20px;}
    .director-pic {
        border-radius:10px;
        padding:10px;
    }
    .aesthetics-info h2 {
        font-size: 26px;
        margin-bottom: 5px;
    }
}