header.scroll{
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter:blur(8px);
  position:fixed;  
  top:0;
  width:100%;
  z-index:99;
  box-shadow:0 0 10px 5px #e0e0e066;
  border-bottom: solid 1px rgba(212, 212, 212, 1);
}
.scroll .header-top{padding:5px 0;}
.scroll .header-top a{padding:2px 0;}
.scroll .header-logo img{width:50px;}
.scroll .header-second {
  padding: 10px 0;
}
.scroll .navbar-nav li a{
  line-height:50px;
  color:var(--balck);
}
.scroll .navbar-nav > li > ul > li > a{
  line-height:150%;
}

header{
    position:absolute;
    width:100%;
    z-index:2;
    transition: background-color 0.3s ease;
    -webkit-transition: background-color 0.3s ease; 
    -moz-transition: background-color 0.3s ease;    
    -o-transition: background-color 0.3s ease;      
}
.header-top{ 
border-bottom: 1px solid  var(--primery);
background:var(--primery-bg);
padding:10px 0;
color:var(--bs-white);
transition: display 0.3s ease;
-webkit-transition: display 0.3s ease; 
-moz-transition: display 0.3s ease;    
-o-transition: display 0.3s ease;      
}
.header-top a{
border-radius: 6px;
border: 1px solid #2C5446;
background: #5F5F5F;
margin:0 93px;
padding:5px 0;
color:var(--bs-white);
position:relative;
text-align:center;
overflow:hidden;
}
.header-top a::after,
.header-top a::before{
 content:'';   
 position:absolute;
 top:0;
 left:40px;
 background:url(../../../public/images/designpath.png) no-repeat center left;    
 height:100%;
 width:100%;
 background-size: contain;
}
.header-top a::after {
    right: 40px;
    left: auto;
    background:url(../../../public/images/designpath-right.png) no-repeat center right;        
    background-size: contain;
}

.header-top a span{
margin:0 18px;
}
.header-second{
    padding-top:27px;
    transition: padding 0.3s ease, box-shadow 0.3s ease; 
    -webkit-transition: padding 0.3s ease, box-shadow 0.3s ease;
    -moz-transition: padding 0.3s ease, box-shadow 0.3s ease;
    -o-transition: padding 0.3s ease, box-shadow 0.3s ease;
}
.header-logo img {
    width: 100px;
  transition: width 0.3s ease;
  -webkit-transition: width 0.3s ease; 
  -moz-transition: width 0.3s ease;    
  -o-transition: width 0.3s ease;        
}
.navbar{
    padding:0;
}
.navbar-nav{
    gap:0 22px;
}
.navbar-nav li a{
  font-weight:600;
   color:var(--bs-white); 
    line-height:60px;
    position:relative;
    display: flex;
    align-items: center;
    transition: line-height 0.3s ease, color 0.3s ease; 
    -webkit-transition: line-height 0.3s ease, color 0.3s ease;
    -moz-transition: line-height 0.3s ease, color 0.3s ease;
    -o-transition: line-height 0.3s ease, color 0.3s ease;
}
.navbar-nav li:hover a{
    color:var(--lightyellow);    
}
.navbar-nav li a.active{
    color:var(--lightyellow);    
    font-weight:900;
}
.navbar-nav li a.active::after{
content:"";
position:absolute;
bottom:10px;
background:var(--lightyellow);
height:1px;
width:100%;
left:0;
}
  /* droprdown css */
.navbar-nav > li {
    position: relative;
  }

  .navbar-nav > li > ul {    
    position: absolute;
    top: 100%;
    left: 0;
    background:var(--bs-white);    
    width:100%;        
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    z-index: 1000;
    pointer-events: none;
  }
  .navbar-nav > li:hover > ul {
    opacity: 1;
    transform: translateY(0); 
    pointer-events: auto;
  }
  
  .navbar-nav > li > ul > li {
    padding:0;
  }
  
  .navbar-nav > li > ul > li > a {
    color:var(--balck);
    display: block;
    padding:12px 10px;
    border-bottom:solid 1px #5f5f5f12;
    line-height:150%;
  }
  .navbar-nav li:hover ul > li > a{
    color:var(--balck);
  }
  .navbar-nav li ul > li > a.active,
  .navbar-nav li ul > li > a:hover{
    color:var(--lightyellow);
  }
  .navbar-nav li ul li a.active::after{
    opacity:0;
  }
  .navbar-nav > li > a svg {
    margin-left: 5px;
  }  
  /* droprdown css end */

.header-second .btn{
    margin-left:22px;
    background:var(--primery);
    color:var(--bg-primery);
    font-weight:600;
}
button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

@media screen and (max-width:992px) {
  .header-top a{margin:0;}
  .header-logo img {
    width: 70px;
}
.navbar-toggler{display:none;}
.collapse:not(.show) {
  display: flex;
  justify-content: end;
}
.navbar {width:86%;}
.navbar-nav {
  gap:10px;
  flex-direction: row;
  margin:0 !important;
}
.navbar-collapse{
  display: flex;
  justify-content: end;
}
.navbar-nav li a{font-size:14px;}
}

@media screen and (max-width:767px) {
  .navbar-collapse{
    flex-flow:wrap;
    justify-content:flex-start;
  }
  .scroll .header-top{display:none;}
  .header-logo img {
    width:60px;
}
.scroll .header-logo img {
  width: 40px;
}
.navbar-toggler span{
  color:var(--bs-white);
  font-size:30px;
  position: absolute;
  right: 20px;
  top: 25px;
}
.navbar-collapse {
  position: absolute;
  left:0;
  top:100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  height: calc(100vh - 130px);
  backdrop-filter: blur(10px);
  opacity: 0;
  -webkit-transform: translateY(100%); 
  -ms-transform: translateY(100%);
  transform: translateY(100%); 
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; 
  -webkit-transition: opacity 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out; 
  pointer-events: none;
}
.navbar-collapse.visible {
  opacity: 1;  
  transform: translateY(0); 
  -webkit-transform: translateY(0); 
  -ms-transform: translateY(0);  
  pointer-events: auto;
}



.scroll .navbar-collapse {
  background: rgba(0, 0, 0, 0.6);
  height:calc(100vh - 62px);
  overflow-x:auto;
}
.navbar-nav > li{padding:0 20px;}
.navbar-nav li a{line-height:30px;border-bottom: solid 1px #ffffff12;}
.navbar-nav li a.active::after{bottom:-1px;}
  .navbar{width:auto;position: inherit;}
  .navbar-nav{flex-direction:column; padding-top:20px; width:100%;}
  .navbar-toggler-icon{filter:brightness(100);}
  .collapse:not(.show) {
    display: none;
  }
  .navbar-nav > li > ul {
    position: relative;
    left: 0;
    box-shadow: none;
    opacity: 1;
    transform: translateY(0px);
    pointer-events:none;
    background:transparent;

}
  .navbar-toggler {
    display:block;
    border: none;
    padding: 0;
  }
  .header-second{padding-top:10px;position:relative;}
  .header-top a span {
    margin: 0 5px;
    font-size: 12px;
}
.header-top svg{width:20px;}
  .header-top a::after, .header-top a::before{
    display:none;
  }
  .header-second .btn {
    margin:20px 20px 0 20px;
    width:100%;
  }
  .scroll  .navbar-toggler span{color:var(--balck);top:19px;font-size: 24px;}
  .navbar-nav > li > ul > li > a{padding-left:20px;border-bottom: solid 1px #ffffff12;}
  .navbar-nav li a,
  .navbar-nav > li > ul > li > a{border-bottom: solid 1px #ffffff36;}
  .navbar-nav > li > ul > li > a,
  .navbar-nav li:hover ul > li > a{color:var(--bs-white);}
  .navbar-nav li ul > li > a:hover{color:var(--lightyellow);}
  .scroll .navbar-nav li a{
    line-height:30px;
    color:var(--bs-white);
  }
  header.scroll{
    backdrop-filter:initial;
  }
  header.scroll:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 62px;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter:blur(8px);  
}
}