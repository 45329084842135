.longtermfreedom .fasater-title{
max-width:100%;
}
.longtermfreedom .fasater-title h2{
    font-size:46px;
} 
.longtermfreedom .fasater-title p {
    max-width: 838px;
    margin: auto;
}
.longtermfreedom .yourjourney-box{
    background:var(--bs-white);
    border:solid 2px var(--golden);
}
.longtermfreedom .yourjourney-box:hover {
    background:var(--golden);
}

.longtermfreedom .yourjourney-box:hover p strong, 
.longtermfreedom .yourjourney-box:hover h5, .longtermfreedom .yourjourney-box:hover p {
    color: var(--dark);
}
.longtermfreedom .yourjourney-pic img{height:220px;}


@media screen and (max-width: 767px) {  
.longtermfreedom .yourjourney-pic img{
height:auto;
}
.longtermfreedom .fasater-title h2{font-size:26px;}
}
