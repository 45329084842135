.hero-section-main {
    padding-top:55px;
    height: 100vh;
    overflow: hidden;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index:1;
}
.HeroSlider {
    width: 100%;
    position:relative;
    z-index: -1;
}
.hero-section-main::before{
    content:"";
    position:absolute;
    top:0;
    height:100vh;
    left:0;
    width:100%;
    background:var(--balck);
    opacity:0.6;
}
.unwanted-skin{
    position: absolute;
    top: 0;
    text-align: center;
    height: 100%;
    margin: auto;
    display:flex;
    width:100%;
}
.unwanted-skin .container{
    max-width:initial;
    padding:0;
}
.book-consultation{
position:absolute;
bottom:40px;
width:100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
}
.book-consultation .btn{
    padding:12px 141px;
    color:var(--lightyellow);
    border: solid 2px var(--primery);
    font-size: 18px;
}
.book-consultation .btn:hover{
background:var(--primery);
color:var(--bs-white);
}
.HeroSlider .img-fluid{
    width:100%;
    height: 100vh;
    object-fit: cover;
}

.hero-info h1{
 color:var(--bs-white);
text-align: center;
font-size: 28px;
margin: auto;
position: relative;
text-decoration: underline;
text-decoration-thickness: 1px; 
text-underline-offset: 3px; 
text-decoration-color:var(--bs-white);
}

.hero-info h2 {
    color: var(--primery);
    font-size: 48px;
    font-weight: 700;
    line-height: normal;
    width: 980px;
    padding:0 20px;
    margin: 27px auto; 
    text-decoration:none;
}

.hero-info p{
   margin:0;
   color:var(--bs-white);
}
.hero-info {
    padding-top:0;
    display:flex;
    flex-direction:column;
}
.guided-customers {
    display: flex;
    align-items: center;
    gap:8px;
    margin-top:14px;
}
.guided-customers p{
    margin:0;
    color:var(--bs-white);    
    font-size: 20px;
    font-weight:600;    
}
.guided-customers p span{
    color:var(--primery);
    font-weight: 700;
}
.customers-group {
    border-radius: 58px;
    border: 1px solid var(--primery);
    padding:8px 10px;
}
.customers-group img{width:38px; height:38px;}
.customers-group .customersimg {
    margin-left: -15px;
}
@media screen and (min-width:1920px) {
    .hero-info{
        padding-top:0;
    }
}

@media screen and (max-width:1239px) {
    .hero-info h1{font-size:20px;}
    .hero-info h2 {
        font-size: 40px;
        width: 790px;
    }
    .book-consultation .btn {
        padding:14px 100px;        
    }
}

@media screen and (max-width:992px) {
.hero-info h2{width:auto;font-size:30px;}
.hero-info h1{font-size: 16px;}
.hero-info p{margin: 0 20px;}
}


@media screen and (max-width:767px) {
.hero-info h2 {
    font-size: 23px;
      
}   
.book-consultation .btn {
    padding: 10px 20px;
}
.guided-customers p{font-size:14px;}   
.customers-group img {
    width: 30px;
    height: 30px;
}
.customers-group .customersimg {
    margin-left: -5px;
}
.customers-group{padding:5px;}
}

/* @media screen and (max-width:575px) {
    .hero-info p,
    .hero-info h2 {    
    text-align:justify;    
}  

} */
