.relief-main{
    position:relative;
    margin-top:150px;
    padding:80px 0;
}
.relief-main::after,
.relief-main::before{
    content:"";
    width:100%;
    height:3px;
    position:absolute;
    top:0;
    left:0;
    background: linear-gradient(45deg, var(--primery-bg), var(--primery));
}
.relief-main::after{
    top:auto;
    bottom:0;    
}
.relief-main h2{
    text-decoration:none;
    background: linear-gradient(90deg, #162731 0%, #C1A13F 83.41%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size:36px;
    font-family:var(--Poppins);
    font-weight: 600;
    line-height: 53px;
}

.polyacrylamide .discomfort-pic {
    text-align: center;
    background: #e5e5e5;
    border-radius:20px;
}

.arthrosamid-work{
position: relative;
}

.arthrosamid-work::after,
.arthrosamid-work::before{
    content:"";
    width:100%;
    height:3px;
    position:absolute;
    top:0;
    left:0;
    background: linear-gradient(45deg, var(--primery-bg), var(--primery));
}
.arthrosamid-work::after{
    top:auto;
    bottom:0;    
}

.arthrosamid-pic img{max-width:660px;border-radius: 12px; width:100%;}
.arthrosamid-info {
    width: 77%;
    margin: auto;
}


.arthrosamid-work h2{
    text-decoration:none;
    background: linear-gradient(90deg, var(--lightyellow) 1.7%, var(--primery-bg) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.arthrosamid-work .discomfort-main{
    gap:50px;
}


.howeffective-work .arthrosamid-pic img{max-width:660px;border-radius:24px;}
.responseunder{
    background:linear-gradient(90deg, var(--primery) 3.01%, #FDEFBD 52.01%, #FDEFBD 63.01%, #B1997B 81.01%);
}
.responseunder h3{
    margin:0;
    color: var(--primery-bg);
    font-family:var( --Poppins);
    font-size: 36px;
    font-weight: 600;
    line-height: 53px;
}


.buttons .btn{
    background: linear-gradient(90deg, #C1A13F 3.01%, #FDEFBD 52.01%, #FDEFBD 63.01%, #B1997B 81.01%);
    color:var(--balck);
}


.iframvideo {
    position: relative;
    padding-bottom: 36%;
    height: 0;
    overflow: hidden;
    max-width:50%;
    background: #000;
    border-radius: 10px;
    margin: auto;
}
  
  .iframvideo iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; /* Remove default border */
  }

@media screen and (max-width:1239px) {
.longtermfreedom .fasater-title h2 {
    font-size:45px;
}

}
@media screen and (max-width:992px) {
.discomfortsection-guidance span{
    font-size:20px;
}
.relief-main h2{
font-size: 25px;
line-height: 35px;
}
.longtermfreedom .fasater-title h2 {
    font-size:30px;
}
.arthrosamid-info{width:auto;}
}
@media screen and (max-width: 767px) {
    .relief-main{margin-top:60px; padding:40px 0;}
    .arthrosamid-work .discomfort-main {
        gap: 30px;
    }
    .responseunder h3{
        font-size: 30px;
        line-height: 40px;
    }
    .ultrasoundguide h3 {
        font-size: 20px;
        line-height: 30px;
        text-align:justify;
    }
    
    .longtermfreedom .fasater-title h2 {
        font-size: 26px;
    }
    .arthrosamid-info{width:100%;}
    .iframvideo {
        padding-bottom:50%;
        max-width:100%;                
    }
}

@media screen and (max-width:575px) {
    .discomfort-pic img {
        max-width: 190px;        
    }
    
    .arthrosamid-info{width:auto;}
    .buttons {flex-direction:column;}
    .fasater-title p {
        text-align: justify;
    }
}