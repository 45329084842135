footer{
    background:url(../../../public/images/footer.png) no-repeat 50%/ cover;
    padding:60px 0 45px 0;    
}
.footer-logo img{
    width:160px;
}
.footer-box{
border-radius: 30px;
background: 
url(../../../public/images/bg-transparent.png) no-repeat 50% / cover;
box-shadow: 0px 27px 110px 0px rgba(0, 67, 105, 0.12); 
backdrop-filter: blur(32px); 
-webkit-backdrop-filter: blur(32px);
gap:30px;      
padding:60px 30px;    
/* width:72%; */
justify-content: space-between;
}
.footer-info strong{
color: var(--bs-white);
font-family:var(--Inter);
font-size: 24px;
line-height: 38px;
}
.footer-info p{
color:#E5EFFF;
font-family:var(--Inter);
font-size: 14px;
line-height: 24px;
margin:0;
}
.footer-info{
    color:var(--bs-white);    
}
.footer-box .btn{
    border: 1px solid rgba(255, 255, 255, 0.53);
    background: var(--SKNJ, linear-gradient(90deg, var(--lightyellow) 1.7%, var(--primery-bg) 100%));
    box-shadow: 0px 14px 120px 0px rgba(255, 255, 255, 0.30);
}

.footer-code{
border-radius: 25px;
border: 4px solid var(--primery);
background: var(--bs-white);
padding:8px 18px;
gap: 5px;
max-width:160px;
}
.footer-code strong{
    text-transform:uppercase;
    color:var(--balck);
    font-size: 12px;
    line-height: 16px;
}

.footer-code.showphone{display:none !important;}

/* footer-second css */
.footer-second{
padding: 20px 0px;
border-top: 1px solid var(--primery);
border-bottom: 1px solid var(--primery);
margin:60px 0 45px 0;
}
.footer-second ul{display:flex; justify-content:space-between;}
.footer-second ul li a{display:flex; gap:10px;
    align-items:center;
    color: var(--bs-white);
    font-family:var(--Inter);
    font-size: 14px;        
}
.footer-second ul li a span{
    width:30px;
    height:30px;
    align-items:center;
    justify-content:center;
    display:flex;
    background:linear-gradient(90deg, #C1A13F 3.01%, #FDEFBD 52.01%, #FDEFBD 63.01%, #B1997B 81.01%);
    border-radius:50%;
}
/* footer-second css end*/

/* copyright css */
.copyright{
    text-align:center;
}
.copyright p{
    margin:0;
    color:var(--bs-white);
    font-family:var(--Inter);
    font-size:14px;
}
/* copyright css end*/

.fixed-btn {
    display:none;
    position: fixed;
    bottom:40px;
    right: 20px;
    background:transparent;
    border: none;        
    z-index:9;
}  
    
.fixed-btn ul {
    margin: 0;
    padding: 0;
    width: 115px;
    position: absolute;
    left:-20%;
    bottom:-8px;
    transform: translate(-50%, -50%) rotate(0deg);
    list-style: none;
    opacity: 0;
    transition: opacity 0.4s ease, transform 0.4s ease;
}

.fixed-btn.active ul {
    opacity: 1;
    transform: translate(-50%, -50%) rotate(180deg);
}

  
  .fixed-btn li {
    display: block;
    position: absolute;    
    transition: transform 0.4s ease, opacity 0.4s ease;
    opacity: 0;
  }
  
  .fixed-btn.active li {
    opacity: 1;
  }
  
  .fixed-btn li:nth-child(1) {
    transform: rotate(0deg) translate(50px) rotate(0deg); 
  }
  
  .fixed-btn.active li:nth-child(1) {
    transform: rotate(0deg) translate(50px) rotate(0deg);
  }
  
  .fixed-btn li:nth-child(2) {
    transform: rotate(45deg) translate(70px) rotate(-45deg);
  }
  
  .fixed-btn.active li:nth-child(2) {
    transform: rotate(45deg) translate(70px) rotate(-45deg);
  }
  
  .fixed-btn img {
    width: 70px;
}  
.fixed-btn .btn{background:var(--primery); width: 110px;transform:rotate(180deg);}

.blurbody::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    height: 100vh;
    width: 100vw;
    z-index:9;
    transition: backdrop-filter 0.3s ease-in-out, background 0.3s ease-in-out;
  }
  

@media screen and (max-width:1239px) {
.footer-first{gap:20px;}
.footer-box{flex-wrap:wrap; padding:20px; gap:20px; justify-content: center;}
.footer-second ul{flex-wrap:wrap;}
.footer-second ul li a{font-size:11px;}
}
        
@media screen and (max-width:992px) {
.footer-info strong {
    font-size: 15px;
    line-height: 24px;
}    
.footer-info p {    
    font-size: 12px;
    line-height: 16px;
    margin: 0;
}
.footer-code img {
    max-width: 80px;
    margin: auto;
}
.footer-code strong {
    font-size: 10px;
    line-height: 12px;
}
.footer-code {
    border-width:2px;
    padding: 8px;
    gap: 5px;
    max-width: 120px;
}
.footer-box .btn {
    padding: 5px 10px;
    font-size: 14px;
}
.footer-box{gap:10px;padding:15px; border-radius: 15px;}
footer{padding:50px 0 30px 0;}
.footer-second{margin:40px 0;}
.footer-second ul{gap:15px 0;}
.copyright p{font-size:12px;}

}

@media screen and (max-width:767px) {
    .fixed-btn{display:block;}
  .footer-first{flex-direction:column;}
  .footer-code.showphone{display:flex !important;border-radius: 10px;}
  .footer-code.hidephone {
    display:none !important;
  }
  .footerinphone {
    display: flex;
    justify-content: space-between;
    align-items:center;
    width:100%;
    gap:20px;
}
.footer-info p,
.footer-info strong {
    text-align: justify;
}
.footer-code img {
    max-width: 74px;
}
.footer-logo img {
    width: 120px;
}

.footer-second ul li p {
    width:80%;
}
.footer-second ul li {
    width: 100%;
}
.footer-info strong{font-size:14px;}
}