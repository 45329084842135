.ultrasound-guidance{
padding: 1px 0;
background: linear-gradient(45deg, var(--primery-bg), var(--primery));
border-radius: 25px;
width: calc(100% - 1px);
}

.ultrasound-inflammation{
    position:relative;
    background:var(--bs-white); 
    border-radius: 25px;
    padding:50px;   
    margin:0 1px;
}
.ultrasound-inflammation span{
color: var(--primery-bg);
font-size:24px;
font-weight: 300;
border-bottom:2px solid var(--lightyellow);
padding-bottom:8px;
display:inline-block;
}
.ultrasound-inflammation h2{
    display:block;
    margin:10px 0 30px 0;
}
.ultrasound-inflammation p{
    font-family:var(--Poppins);
    text-align: justify;
}
.ultrasound-inflammation strong{
    color:var(--primery);    
}

/* keybenefits css */
.keybenefits{
   background: var(--SKNJ-NEW, linear-gradient(90deg, #C1A13F 3.01%, #FDEFBD 52.01%, #FDEFBD 63.01%, #B1997B 81.01%));
}
.keybenefits h2{
    margin-bottom:30px;
}
.keybenefits-main{
    gap:50px;
}
.keybenefits-pic {
    width: 50%;
}
.keybenefits-pic img{
border-radius: 12px;
}

.keybenefits-info{width:50%;}
.inflammation-swelling:last-child{margin-bottom:0;}
.inflammation-swelling {
    display: flex;
    justify-content: space-between;
    gap: 23px;
    margin-bottom: 20px;
}
.inflammation-swelling p{margin-bottom:0;
    font-size: 14px; 
    font-family:var(--Inter);
    text-align: justify;
}
.inflammation-swelling h5{
font-family:var(--Inter);
font-size: 18px;
font-weight: 500;
margin-bottom: 6px;
}

.number{
    position:relative;
}
.number span{
    border: 1px solid var(--primery-bg);
    background: #F0F6FF;
    width:40px;
    height:40px;
    border-radius:50%;
    display:flex;
    align-items:center;
    justify-content:center; 
    font-family:var(--Inter);
    font-size: 14px;
    font-weight: 600;    
}

.number::after {
    content: '';
    position: absolute;
    top: 51px;
    height: 80px;
    width: 1px;
    background: #F0F6FF;
    left: 20px;
}

.inflammation-swelling:last-child .number::after{
display:none;
}

/* keybenefits css end*/


/* treatedconditions css */
.treatedconditions .ultrasound-guidance{
padding:4px 0;
max-width: 840px;
margin: auto;
}
.treatedconditions .ultrasound-inflammation{
    background: #EDE3C6;
    margin:0 4px;
}

.treatedconditions .ultrasound-inflammation p{
    font-family:var(--Urbanist);
}

.ultrasound-inflammation ul {
    list-style:outside;
    padding-left:16px;
}

/* treatedconditions css end*/
@media screen and (min-width:1920px) { 
.ultrasound-inflammation {        
    margin: 0 1px 0 2px;
}
}
@media screen and (max-width:992px) {
.ultrasound-inflammation{padding:20px;}
.ultrasound-inflammation span{
font-size: 20px;
line-height: 30px;
}
.keybenefits-main {
    gap: 30px;
}
}

@media screen and (max-width: 767px) {
.keybenefits-pic {
    width: 100%;
    text-align: center;
}
.keybenefits-pic img {
    max-width: 550px;
    width: 100%;
}
.keybenefits-main{flex-direction:column-reverse;}
.keybenefits-info {
    width: auto;
}
.inflammation-swelling{gap:15px;}

}