@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
*{
    box-sizing:border-box;
    margin:0;
    padding:0;
    --primery:#C1A13F;
    --golden:#E9E2BD;
    --lightyellow:#E8C353;
    --primery-bg:#162731;
    --black:#262626;
    --dark:#333;    
    --balck:#000;
    --Poppins: "Poppins", sans-serif;
    --Inter:"Inter", sans-serif;
    --Urbanist:'Urbanist', Arial, sans-serif;
}

body{
    font-family:var(--Urbanist);
    font-size:16px;
    line-height:24px;
    font-weight:400;
    color:var(--dark);
}
.container{
    max-width:1240px;
    padding:0 20px;
    margin:auto;
}
.container-fluid{
    max-width:1920px;
    width:100%;
    padding:0;
}
ol,
ul{
    padding:0;
    margin:0;
    list-style:none;
}
a{
    text-decoration:none;
}

:hover,
:focus-visible,
:focus{
    outline:none;
    box-shadow:none;
}

.btn{
display: flex;
justify-content: center;
align-items: center;
gap: 8px;
padding:10px 24px;
font-size:16px;
border-radius:8px;
background: var(--primery-bg);
color:var(--bs-white);
border:none;
transition: background-color 0.5s ease, color 0.5s ease; 
}
.btn:focus-visible, 
.btn:hover{
    border:none;
    background:var(--primery-bg);
    color:var(--bs-white);
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active,
.btn-check:checked+.btn:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible, .btn:first-child:active:focus-visible, :not(.btn-check)+.btn:active:focus-visible {
    box-shadow:none;
    background:var(--primery-bg);
    color:var(--bs-white);
}

h2{
    color:var(--black);    
    font-family:var(--Urbanist);
    font-size: 48px;
    font-weight: 700;
    line-height: 150%;    
    margin:0;
    position:relative;
    display: inline-block;
    text-decoration: underline;
    text-decoration-thickness: 1px; 
    text-underline-offset: 3px; 
    text-decoration-color:var(--black);    
}

.pt0{
padding-top:0;
}
.pb0{
    padding-bottom:0;
    }
.pt{
    padding-top:150px;
}

.pb{
   padding-bottom:150px;
}
.pt80{
    padding-top:80px;
}
.pb80{
    padding-bottom:80px;
}
.mt{
    margin-top:150px;
}
.mb80{
    margin-bottom:80px;
}
p:last-child{margin-bottom:0;}
.painfree h4{     
    color: #D7D7D7;
    text-align: center;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--balck);
    font-size:45px;
    font-style: italic;
    font-weight: 900;
    margin: 0;
    text-transform: uppercase;
    background: linear-gradient(45deg, var(--primery-bg), var(--primery));
    -webkit-background-clip: text;
    -webkit-text-stroke: 5px transparent;
    letter-spacing: 3px    
}

.swiper-button-prev:after, .swiper-button-next:after{font-size:20px !important;}

.swiper-button-prev{left:0px !important;}
.swiper-button-next{right:0px !important;}
.swiper-pagination-bullet-active{background:var(--black) !important;}

.doctorissues {
    position: absolute;
    top: 100%;
    width: 100%;  
    display:none;
  } 
.lottieanimation svg {
    width: 60px !important;
    height: 60px !important;
    opacity: 0.2;    
}

.lottieanimation svg:hover {
    opacity: 1;
    fill: var(--black) !important;
}

/* .lottieanimation::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;    
    border-radius: 50%;
    filter: blur(20px);
    opacity: 0;
    transition: 1s;
    z-index: 1;
    background: linear-gradient(45deg, var(--lightyellow), var(--primery));
}

.lottieanimation:hover::before {
    opacity: 1;
} */

/* General animation setup for each lottieanimation */
.lottieanimation {
    position: absolute;
    opacity: 0.2;
    transition: opacity 0.5s ease-in-out;
}

/* Adding hover effects */
.lottieanimation:hover {
    opacity: 1;
}

/* Defining animations for different directions */
@keyframes move-left-right {
    0% { transform: translateX(-100px); }
    100% { transform: translateX(100px); }
}

@keyframes move-right-left {
    0% { transform: translateX(100px); }
    100% { transform: translateX(-100px); }
}

@keyframes move-top-bottom {
    0% { transform: translateY(-100px); }
    100% { transform: translateY(100px); }
}

@keyframes move-bottom-top {
    0% { transform: translateY(100px); }
    100% { transform: translateY(-100px); }
}

/* Applying the animations with different directions */
.lottie-1 {
    animation: move-left-right 50s ease-in-out infinite alternate;
    left:40%;
    top: 40px;
}
.lottie-2 {
    animation: move-right-left 60s ease-in-out infinite alternate;
    right: 13%;
    top: 7%;
}
.lottie-3 {
    left:3%;
    top:20%;
    animation: move-top-bottom 50s ease-in-out infinite alternate;
}
.lottie-4 {
    animation: move-bottom-top 80s ease-in-out infinite alternate;
    right: 3%;
    top: 90%;
}
.lottie-5 {
    animation: move-top-bottom 80s ease-in-out infinite alternate;
    left: 3%;
    top: 80%;
}
.lottie-6 {
    animation: move-bottom-top 60s ease-in-out infinite alternate;
    right: 4%;
    top: 40%;
}
.lottie-7 {
    animation: move-left-right 70s ease-in-out infinite alternate;
    left: 54%;
    top: 35%;
}
.App{
width:100%;
overflow-x:hidden;
}
.ball {
width:200px;
height:200px;
border-radius: 50%;
position: absolute;	
transform: translate(-50%, -50%);
z-index:-1;
opacity:0.2;
filter:blur(60px);
background:var(--primery);
}

  
@media screen and (max-width:1239px) {
    .pt{
        padding-top:80px;
    }  
    .mt{
        margin-top:80px;
    }  
    .pb{
        padding-bottom:80px;
    }
    .pt80{
        padding-top:60px;
    }
    .pb80{
        padding-bottom:60px;
    }    
    .mb80{
        margin-bottom:60px;
    }  
    h2{
        font-size:38px;
    }
}
@media screen and (max-width:992px) {
body{font-size:14px; line-height:20px;}
h2{
    font-size:30px;
    line-height:40px;
}
.painfree h4{font-size:30px;}
.btn{font-size:14px;gap: 5px;padding: 8px 14px;}
}

@media screen and (max-width: 767px) {  
  .pt{
    padding-top:60px;
}    
.pb{
   padding-bottom:60px;
}
.pt80{
    padding-top:40px;
}
.mt{
    margin-top:40px;
}
.pb80{
    padding-bottom:40px;
}    
.mb80{
    margin-bottom:40px;
} 
.painfree h4{
    font-size:25px;
    line-height: 35px;
    -webkit-text-stroke: 3px transparent;
}
.lottieanimation svg{
    width:40px !important;
    height:40px!important;
    opacity: 0.1;
}
}
