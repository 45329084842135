.jointpain {
    width: 74%;
    margin:auto auto 80px;
}
.jointpain h2{
    margin-bottom:10px;
}
.jointpain  p{
    margin-bottom:0;
    text-align:justify !important;
}
.pain-main {
    max-width: 1100px;
    margin: 30px auto auto auto;
}
.pain-box{
    border-right:solid 12px var(--primery-bg);
    width:50%;
}
.pain-box:last-child{
    border:none;
    justify-content: start;
}
.pain-info{
    border-radius: 0px 0px 0px 40px;
    background: rgba(193, 161, 63, 0.30);
    display: flex;
    padding:20px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap:24px;
    text-align: justify;
    min-height:366px;
    backdrop-filter: blur(3px);
}
.pain-info p{
    margin-bottom:0;
    color: var(--dark);
    text-align: justify;    
    font-size:20px;    
    font-weight: 500;
    line-height: 150%; 
}
.pain-box:last-child .pain-info{
 border-radius: 0px 40px 0px 0px;
 align-items: flex-start;
 min-height:351px;
}


@media screen and (max-width:1239px) {
.jointpain {width: auto;}
.pain-info p{font-size:16px;}
.pain-info{min-height: 314px;}
.pain-box:last-child .pain-info {min-height: 300px;}
.pain-main {                
    width: 933px;
}
}

@media screen and (max-width:992px) {
.pain-main {
    width: 710px;
}
   .pain-info{padding:20px; gap:0;}
   .pain-info svg {
    width: 35px;
}
.pain-info p {
    font-size: 14px;
}
.pain-box:last-child .pain-info {
    min-height: 227px;
}
.pain-info {
    min-height: 264px;
}
.pain-box:last-child .pain-pic img{
    height: 263px;
    object-fit: cover;
}
.jointpain p {
    text-align: justify;
}
}

@media screen and (max-width:767px) {
.pain-main{width:100%; flex-direction:column;max-width:390px;}
.pain-box{width:auto; border-right:none;border-bottom: solid 5px var(--primery-bg);}
.pain-box:last-child .pain-info,
.pain-info{border-radius:0;}
.pain-box:last-child .pain-pic img {
    height: initial;    
}
}