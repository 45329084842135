.debilitating p{
color:var(--primery-bg);
text-align: justify;
font-family:var(--Poppins);
font-weight: 500;
margin-bottom:0;
}

.debilitating-pic{
border-radius: 20px;
background: linear-gradient(45deg, var(--primery), var(--primery-bg));
margin:auto;
padding:2px 0;
max-width:540px;
margin-top:30px;
}
.debilitating-pic img{
    width:100%;
    max-width:536px;
    border-radius: 20px;
}

.significantly{
    font-family:var(--Poppins);
    text-align: justify;
    color:var(--primery-bg);  
    position:relative;
    margin-top:80px;
    padding:20px 0 20px 50px;
}

.significantly::before{
    content:"";
    position:absolute;
    left:0;
    width:5px;
    height:100%;
    top:0;
    background: linear-gradient(45deg, var(--primery), var(--primery-bg));
}

.introducing{position: relative;}
.introducing::after, .introducing::before {
    content: "";
    width: 100%;
    height: 3px;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(45deg, var(--primery-bg), var(--primery));
}

.introducing::after{
    top:auto;
    bottom:0;
}
.introducing .discomfort-pic{
    width:31%;
}
.introducing .discomfort-info {
    width: 40%;
}
.introducing .discomfort-main{gap:50px;}

.internationally-standard .doseprf{
margin-top:0;
}
.internationally-standard .buttons .btn:last-child{display:none;}
.gradinentthree .ultrasoundguide h3{margin-bottom:0;}

@media screen and (max-width:1239px) {
    .introducing .discomfort-main{gap:30px;}
}
@media screen and (max-width:992px) {
.introducing .discomfort-info {
    width: 70%;
}
.significantly{
padding: 10px 0 10px 20px;
}
.significantly::before{width:2px;}
.restoreLu-brication-main{gap:20px;}
.restoreLu-brication-box span{
    font-size:15px;    
    line-height: 18px;
}
.restoreLu-brication-box{padding:10px;}
.internationally-standard .doseprf p{
width:auto;
}
.internationally-standard .doseprf p br{display:none;}
}
@media screen and (max-width: 767px) {
.restoreLu-brication-main{flex-flow:wrap;}
.restoreLu-brication-box{width:auto;margin-bottom:60px;}
.restoreLu-brication-box:last-child{margin-bottom:0;}
.debilitating-pic{padding:2px;}
.introducing .discomfort-info {
    width:auto;
}
.introducing .discomfort-pic {
    width:70%;
}
}