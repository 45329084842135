.conditions-wetreat{
    overflow:hidden;
}
.conditions-main{
    margin-top:50px;
}
.title-three {
    padding:88px 0;
    text-align: center;
    padding-left: calc(100% - 586px);
}
.conditions-box:last-child .title-three{padding-right: calc(100% - 605px); padding-left:0;}

.title-three h3{
color: var(--primery-bg);
font-size: 64px;
font-weight: 700;
line-height: 150%;
margin:0;
}
.title-three h3 span{
color:var(--lightyellow);
-webkit-text-stroke-width: 1px;
 -webkit-text-stroke-color:var(--balck);
}
.conditions-box{
    width:51%;
    display:flex;
    flex-direction:column;
    margin:-1px -3px 0 -5px;
} 
.conditions-box:last-child{
    flex-direction:column-reverse;
}
.conditions-box:last-child .conditions-info{    
    border-radius: 25px 0 0 0px;
    background: linear-gradient(45deg, #C1A13F, #162731);
    min-height:272px;
}
.conditions-box:last-child .osteoarthritis{
    border-radius:20px 0px 0 0px; 
    margin:8px 0 0 8px;    
    padding-left:60px;
    padding-right: calc(100% - 605px);
}

.conditions-info{
    border-radius: 0px 0px 25px 0px;
    position: relative;    
    z-index: 1;
    background: linear-gradient(45deg, var(--primery-bg), var(--primery));
}
.osteoarthritis {
    background: var(--golden);
    padding:50px;    
    border-radius: 0px 0px 20px 0px;
    margin:0 8px 8px 0;    
    display: flex;
    align-items: center;
    min-height:264px;
    padding-left: calc(100% - 586px);
}
.conditions-info ul {
    display: flex;
    flex-wrap: wrap;
    gap:20px 0;
    justify-content: space-between;
}
.conditions-info ul li.fasciitis{line-height:16px;}
.conditions-info ul li{
    width:43%;
    color: var(--dark);
    font-family:var(--Poppins);
    font-size: 16px;    
    font-weight: 500;
    line-height: 150%;
    list-style: outside;
}
.conditions-info ul li small{
   font-weight:300; font-size: 13px;
 }


 @media screen and (max-width:1239px) {
.osteoarthritis{padding:30px 30px 30px 42px;}
.conditions-box:last-child .osteoarthritis{padding-left:50px;}    
.conditions-info ul li{font-size:14px;width:49%;}
.title-three h3{
    font-size:50px;
}
 }
        
@media screen and (max-width:992px) {
.conditions-info ul{
    flex-direction:column;
    gap:5px 0;
}
.conditions-info ul li{width:auto;}
.title-three h3{font-size: 40px;}
    
}

@media screen and (max-width:767px) {
.conditions-wetreat{padding-left:20px; padding-right:20px;}    
.conditions-main{flex-direction:column;}
.conditions-box{width:100%; margin:0;}
.title-three {padding:10px 0;background:var(--bs-white);border-radius:10px 10px 0 0;}
.osteoarthritis {
    border-radius: 0 0 10px 10px;
    margin:0;
    padding:20px 20px 20px 35px;
    min-height: auto;
}
.conditions-box:last-child .osteoarthritis {
    padding-left: 35px;
    margin: 0;
    border-radius:0 0px 10px 10px;
}
.conditions-box:last-child {
    flex-direction: column;
    margin-top: 20px;
}
.conditions-box:last-child .conditions-info {
    min-height: initial;
    border-radius: 0 0 0 10px;
}
.conditions-box{padding: 5px;border-radius: 10px;
    background: linear-gradient(45deg, var(--primery-bg), var(--primery));
}
.conditions-info{border-radius:0;background: none;}
.conditions-box:last-child .title-three {
    padding-right: 0;
    padding-left: 0;
}
.title-three h3 {
    font-size: 32px;
}
}