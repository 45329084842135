.yourjourney-main{
    gap:30px;padding-top:30px;
}

.yourjourney-box{
width:32%;
display: flex;
padding: 20px;
flex-direction: column;
gap: 14px;
border-radius:12px;
background:var(--golden);

-webkit-transition: background 0.5s ease-in-out, -webkit-text-stroke-color 0.5s ease-in-out;
-moz-transition: background 0.5s ease-in-out, -webkit-text-stroke-color 0.5s ease-in-out;
-o-transition: background 0.5s ease-in-out, -webkit-text-stroke-color 0.5s ease-in-out;
transition: background 0.5s ease-in-out, -webkit-text-stroke-color 0.5s ease-in-out;
}

.yourjourney-box:hover{
    background:var(--primery-bg);
}
.yourjourney-box:hover p strong,
.yourjourney-box:hover h5,
.yourjourney-box:hover p{
color:#ffffff82;
}
.yourjourney-box h5 {
    color:var(--balck);
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 30px;
    border-bottom: 3px solid var(--primery-bg);
    padding-bottom: 9px;
    margin-bottom: 15px;
}
.yourjourney-box p{
color:#666;
text-align:justify;
margin:0;
}
.yourjourney-box p strong{
color:var(--balck);
}
.yourjourney-pic img {
    width: 100%;
    object-fit: cover;
    height: 180px;
}


@media screen and (max-width:1024px) {
.yourjourney-box h5{font-size:18px;gap:15px;}
.yourjourney-box{padding:10px;}
.yourjourney-pic img{height:120px;}
    
}
    
@media screen and (max-width:992px) {
.yourjourney-main {
    flex-wrap: wrap;
}
.yourjourney-box{width:47.8%;}   
.yourjourney-box:last-child {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items:center;
}
.yourjourney-box:last-child .yourjourney-pic {
    width: 100%;
}

.yourjourney-pic img {
    height: 160px;
}
}

@media screen and (max-width:767px) {
 .yourjourney-box{width:100%;}
 .yourjourney-main{gap:20px;}
 .yourjourney-box:last-child{flex-direction: column;}
 .yourjourney-box h5 {
    font-size: 16px;
}
 .yourjourney-box h5 svg {
    width: 35px;
    height: 30px;
}
.yourjourney-pic img {
    height:auto;
}
}