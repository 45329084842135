.osteoarthritis-remained{
    background:url(../../../public/images/Home/yourjourney-bg.jpg) no-repeat center center;
    padding:300px 0;
    margin-top:150px;
    background-size:cover;
    position: relative;
}
.osteoarthritis-remained::before{
content:"";    
position:absolute;
background:var(--balck);
height:100%;
width:100%;
opacity:0.5;
top:0;
}
.osteoarthritis-remained .container{position: relative;}
.gradiant-title h2{
    background:linear-gradient(90deg, #C1A13F 3.01%, #FDEFBD 52.01%, #FDEFBD 63.01%, #B1997B 81.01%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;    
    text-decoration:none; 
    margin-bottom:30px;
}
.gradiant-title h2::after {
    content: '';
    position: absolute;
    left: 0;
    bottom:14px;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, #C1A13F 3.01%, #FDEFBD 52.01%, #FDEFBD 63.01%, #B1997B 81.01%);
}
.facilities-info {
    width:73%;
    margin: auto;
}
.osteoarthritis-remained P{
    color:var(--bs-white);
    margin-bottom:40px;        
}

/* your-comfort css*/
.your-comfort{
background:var(--primery-bg) url(../../../public/images/Home/abstractdesign.svg) no-repeat top center;
padding:220px 0;
}
.comfort-logo{
    display: flex;    
    justify-content: center;
    margin-bottom:50px;    
}
.comfort-title h2{
    color:var(--lightyellow);
    text-decoration-color:var(--lightyellow);
}
.comfort-title {
    margin-bottom: 30px;
}
.comfort-logo img {
    max-width:300px;
}

.comfort-info p{
    color:var(--bs-white);    
    margin: auto auto 40px;
    text-align: justify;
}
.osteoarthritis-remained P:last-child,
.comfort-info p:last-child{margin-bottom:0;}
/* your-comfort css end*/


@media screen and (max-width:1239px) {
.osteoarthritis-remained{
    margin-top:80px;
    padding:120px 0;
}
.your-comfort{padding:120px 0;} 
.comfort-logo img {
    max-width: 250px;
}      
}
    
@media screen and (max-width:992px) {
    .gradiant-title h2::after{bottom:0;}   
    .osteoarthritis-remained {
        padding: 80px 0;
    }
    .facilities-info{width:auto;} 
    .comfort-info p,
    .osteoarthritis-remained P{margin-bottom: 16px;}
    .your-comfort {
        padding:80px 0;
    }

    .comfort-logo img {
        max-width: 180px;
    }

}

@media screen and (max-width:767px) {
    .osteoarthritis-remained{padding:60px 0;}
}