.ultrasound-injections h2{font-weight:600; text-decoration:none;}

.ultrasound-main{
   gap:30px;
   margin-top:120px;
}
.ultrasound-box:hover .ultrasound-no{
    color:var(--lightyellow);
    -webkit-text-stroke-color:transparent;
}
.ultrasound-no{
color: rgba(22, 39, 49, 0.30);
-webkit-text-stroke-width: 1px;
-webkit-text-stroke-color:#99C0FF;
font-family:var(--Inter);
font-size: 120px;
font-weight: 600;
position: absolute;
top: -34px;
left: 0;
z-index: -1;

-webkit-transition: color 0.5s ease-in-out, -webkit-text-stroke-color 0.5s ease-in-out;
-moz-transition: color 0.5s ease-in-out, -webkit-text-stroke-color 0.5s ease-in-out;
-o-transition: color 0.5s ease-in-out, -webkit-text-stroke-color 0.5s ease-in-out;
transition: color 0.5s ease-in-out, -webkit-text-stroke-color 0.5s ease-in-out;
}
.ultrasound-box {
width: 32%;
position: relative;
display: flex;
padding: 20px;
flex-direction:column;
align-items:flex-start;
gap: 15px;
border-radius: 12px;
background: var(--golden);
-webkit-transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
-moz-transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
-o-transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}

.ultrasound-box:hover {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);

    -webkit-box-shadow: 0 0 25px 1px #00000024;
    -moz-box-shadow: 0 0 25px 1px #00000024;
    box-shadow: 0 0 25px 1px #00000024;
}
.ultrasound-box span{
color:#4C4C4C;
font-size:20px;
font-weight: 500;
margin-bottom:20px;
display: block;
}
.ultrasound-box p{margin-bottom:0; text-align:justify;}


@media screen and (max-width:1239px) {
    .ultrasound-no{
        font-size:80px;
        top: -27px;
    } 
}

@media screen and (max-width:992px) {
.ultrasound-box span{font-size: 16px;margin-bottom: 10px;}
.ultrasound-main{gap: 15px;margin-top:90px;}

}


@media screen and (max-width:767px) {
    .ultrasound-main{gap:70px;
        flex-flow:wrap;
    }  
    .ultrasound-box{width:100%;}
}